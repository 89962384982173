import { Api } from "@/apis";
export const allProps = {
  isShowBtn: {
    addBtn: true,
  },
  dialog: {
    isShow: false,
    title: "编辑",
  },
  data: {},
  items: [
    {
      label: "类型",
      prop: "type",
      type: ["table", "add", "edit"],
      Ttype: "slot",
      Ftype: "select",
      list: [],
    },
    {
      label: "名称",
      prop: "partner_name",
      type: ["table", "add", "edit"],
    },
    {
      label: "分成（%）",
      prop: "divide",
      type: ["table", "add", "edit"],
    },
    {
      label: "汇款银行",
      prop: "bank",
      type: ["table", "add", "edit"],
    },
    {
      label: "银行卡号",
      prop: "card",
      type: ["table", "add", "edit"],
    },
    {
      label: "操作",
      prop: "operation",
      Ttype: "slot",
      type: ["table"],
      width: "150px",
    },
  ],
  api: Api.partner,
};
