<template>
  <div>
    <AllPage ref="AllPage" :allProps="allProps">
      <template v-slot:type="scope">
        <span
          v-for="item in typeList"
          :key="item.id"
          v-show="scope.data.type === item.id"
          >{{ item.name }}</span
        >
      </template>
      <template v-slot:operation="scope">
        <el-button
          size="small"
          @click.native.prevent="dialogForm('编辑', scope.data)"
        >
          编辑
        </el-button>
        <el-button
          size="small"
          @click.native.prevent="deleteRow(scope.data.id)"
        >
          删除
        </el-button>
      </template>
    </AllPage>
  </div>
</template>
<script>
import { Api } from "@/apis";
import { allProps } from "./config";
import { messageBox } from "@/utils/messageBox";
export default {
  components: {
    AllPage: () => import("@/components/AllPage/AllPage.vue"),
  },
  provide() {
    return {
      changeRuleForm: function () {},
    };
  },
  data() {
    return {
      allProps,
      Api,
      typeList: [
        { name: "设备方", id: "hardware" },
        { name: "场地方", id: "field" },
        { name: "内容方", id: "content" },
      ],
    };
  },
  created() {
    this.allProps.items.forEach((item) => {
      if (item.prop === "type") {
        item.list = this.typeList;
      }
    });
  },
  methods: {
    dialogForm(title, row = {}) {
      allProps.dialog = {
        isShow: true,
        title,
      };
      allProps.data = { ...row };
    },
    changeTableList(data) {
      let newdata = [...data.content, ...data.field, ...data.hardware];
      return newdata;
    },

    async deleteRow(id) {
      let result = await messageBox(Api.shop.dele, { id });
      if (result) this.$refs.AllPage.getTableList();
    },
  },
};
</script>
